.video-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  transform: translate(-50%, -50%);


  background: black;

  padding: 8px;

  &__player {
    width: 100%;
    height: 100%;
  }
}
