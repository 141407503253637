.zone-1 {
  .loading-screen {
    background: url('./LoadingScreen 1.png') no-repeat center right / contain, #2a1f00;
    color: white;
  }
}

.zone-2a {
  .loading-screen {
    background: url('./LoadingScreen 2A.png') no-repeat center right / contain, #000000;
    color: white;
  }
}

.zone-2b {
  .loading-screen {
    background: url('./LoadingScreen 2B.png') no-repeat center right / contain, #000000;
    color: white;
  }
}

.zone-3 {
  .loading-screen {
    background: url('./LoadingScreen 3.png') no-repeat center right / contain, #000000;
    color: white;

    &__disclaimer {
      p {
        font-size: 14px;
      }
    }
  }
}
