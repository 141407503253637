$black: #000000;
$white: #FFFFFF;
$terracotta-light: #F4BD92;
$terracotta-dark: #C66B37;
$concrete-light: #E4E3DE;
$concrete-dark: #C9C6BD;
$forest-light: #9BA84B;
$forest-dark: #6D7920;
$gold-light: #E3AE14;
$gold-dark: #C78800;
$teal-light: #B7E4E3;
$teal-dark: #559F9E;
