.loading-screen {
  position: absolute;
  inset: 0;

  display: flex;
  flex-flow: column;
  align-items: stretch;

  padding: 56px 0 88px 72px;


  // container uses button for accessability, so we have to reset default styles
  border: none;
  text-align: start;
  text-transform: none;

  &__title {
    margin-bottom: 12px;
  }

  &__content {
    display: flex;
    flex-flow: column;

    padding: 0 0 10px;

    overflow-y: auto;

    p {
      margin-bottom: 1em;
      font-size: 16px;
      max-width: 25vw;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__disclaimer {
    position: absolute;
    bottom: 48px;
    right: 72px;

    padding: 16px;
    border-radius: 4px;

    background-color: rgba(33, 33, 33, 0.8);

    font-style: italic;

    p {
      margin-bottom: 1em;
      font-size: 16px;
      max-width: 35vw;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__video {
    position: absolute;
    top: 142px; // 56px (padding) + 74px (heading) + 12px (mb)
    right: 72px;

    display: flex;
    flex-flow: column;
    align-items: flex-end;
    justify-content: flex-start;

    & video {
      width: 40vw;
      height: 22.5vw;
    }
  }

  &__ready-message {
    position: absolute;
    bottom: 48px;
  }

  &__bar-outer {
    position: absolute;
    bottom: 48px;

    width: 265px;
    height: 35px;
    background: url("../../assets/progress-bar-empty-light.png") center / 100% 100%;
  }

  &__bar-inner {

    height: 100%;
    background: url("../../assets/progress-bar-full-light.png") center / 100% 100%;
  }

  &__bar-text {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    color: #000000;
  }
}
