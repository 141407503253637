.footer-section {
  padding: 64px 84px;

  @media only screen and (max-width: 960px) {
    padding: 32px 40px;
  }

  @media only screen and (max-width: 600px) {
    padding: 64px 32px;
  }

  &__content {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-start;

    @media only screen and (max-width: 960px) {
      flex-flow: column;
      align-items: center;
    }

    @media only screen and (max-width: 600px) {
      align-items: flex-start;
    }
  }

  &__info, &__link-list, &__support {
    display: flex;
    flex-flow: column;
    width: 20%;

    @media only screen and (max-width: 1280px) {
      width: 30%;
    }

    @media only screen and (max-width: 960px) {
      width: auto;
      max-width: 300px;
      margin-bottom: 56px;
      text-align: center;

      &:last-child{
        margin-bottom: 0;
      }
    }

    @media only screen and (max-width: 600px) {
      align-items: flex-start;
      text-align: inherit;
    }
  }
}

.footer-info {
  &__name {
    margin-bottom: 32px;
  }
}

.footer-links {
  &__link {
    margin-bottom: 16px;
    font-weight: 400;
  }

  &__icon {
    margin-left: 12px;
    vertical-align: bottom;
  }
}

.footer-support {
  &__image {
    width: 147px;
    height: auto;
  }
}
