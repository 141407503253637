@import "../../variables";

.online-museum-section {
  display: flex;
  flex-flow: column;
  align-items: center;

  padding: 24px;

  @media only screen and (max-width: 600px) {
    padding: 48px 32px;
  }

  &__content {
    @media only screen and (max-width: 600px) {
      align-items: flex-start;
      text-align: inherit;
    }
  }

  &__text {
    margin-bottom: 24px;
  }

  &__button {
    background: $terracotta-dark;
  }
}
