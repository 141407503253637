.mobile {
  width: 100vw;
  height: 100vh;
  padding: 24px 8px;
  display: flex;
  flex-flow: column;
  align-items: center;
  box-sizing: border-box;
  background: url('../../assets/hand.png') bottom center/contain no-repeat, $gold-light;

  h1 {
    max-width: 90vw;
    text-align: center;
    font-size: 2rem;
  }
  p {
    max-width: 90vw;
    text-align: center;
    font-size: 1rem;
  }
  &__logo {
    max-width: 90vw;
    height: 22.5vw;
  }
}