.unity {
  height: 100%;
  width: 100%;
  max-height: 100vh;
  #unity-canvas {
    width: 100%;
    height: 100%;
  }

  #unity-container {
    max-height: 100vh;
    height: 100%;
    width: 100%;

    .mobile-fix {
      @media screen and (max-width: 500px) {
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS devices */
          max-height: 85vh;
          overflow: hidden;
        }

        @supports not (-webkit-touch-callout: none) {
          /* CSS for other than iOS devices */
          max-height: 84vh;
          overflow: hidden;
        }
      }
    }

    .progress {
      text-align: center;
    }

    #unity-loading-bar {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      padding: 20vh 4vw;

      .message {
        max-width: 32vw;
      }

      #unity-progress-bar-empty {
        position: absolute;
        bottom: 20vh;
      }
      #unity-ready-message {
        position: absolute;
        bottom: 20vh;
      }
    }
  }
  iframe {
    height: 100vh !important;
    width: 100vw !important;
    border: none;
    max-width: 100%;
    max-height: 99%;
  }

  #unity-logo {
    //background: url('../../assets/logo.png') no-repeat center;
    background-size: contain;
  }
}

.iframe {
  height: 100vh !important;
  width: 100vw !important;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  iframe {
    height: 100vh !important;
    width: 100vw !important;
    border: none;
    max-width: 100%;
    max-height: 100%;
  }
}

.old-devices {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 350px;
  margin: 0 auto;

  p {
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
  }
  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  a {
    color: black;
  }
}

.example-iframe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: lightblue;
  iframe {
    width: 80vw;
    height: 80vh;
    border: none;
  }
}

#unity-fullscreen {
  position: absolute;
  bottom: 100px;
  right: 10px;

  background: #fff;
  color: #3cc1f3;
  min-width: 150px;
  min-height: 40px;
  border: none;
  font-size: 20px;

  &:hover {
    background-color: red;
  }
}
