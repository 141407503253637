@import "../../variables";

// Breakpoints
// 960: Reduce padding, font size and move to single column
// 600: Further reduce padding, font size, and center button

.header-section {
  background: url('../../../assets/hand.png') bottom center no-repeat, $gold-light;

  padding: 64px 84px;
  min-height: 648px;
  box-sizing: border-box;

  @media only screen and (max-width: 960px) {
    padding: 32px 40px;
    min-height: 616px;
  }

  @media only screen and (max-width: 600px) {
    padding: 26px 32px 96px;
  }

  &__content {
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
      align-items: flex-start;
    }
  }

  &__group {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 960px) {
      flex-flow: column;
      align-items: flex-start;
    }
  }

  &__logo {
    max-height: 96px;
    width: auto;

    @media only screen and (max-width: 960px) {
      max-height: 78px;
      margin-bottom: 21px;
    }

    @media only screen and (max-width: 600px) {
      max-height: 54px;
    }
  }

  &__button {
  }

  &__subtitle {
    align-self: flex-end;
    text-align: right;

    font-size: 96px;
    line-height: 92%;

    @media only screen and (max-width: 960px) {
      font-size: 80px;
    }

    @media only screen and (max-width: 600px) {
      font-size: 64px;
      text-align: inherit;
      align-self: flex-start;
      letter-spacing: -0.02em;
    }
  }
}
