.unity-player {
  &--fullscreen {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  &__canvas {
    width: 100%;
    height: 100%;
  }
}
