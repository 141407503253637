@import '../variables';

.custom-button {
  background: $black;
  padding: 17px 24px;
  color: $white;
  text-decoration: none;
  text-transform: uppercase;

  line-height: 1em;

}
