@import '../variables';

.section {
  display: flex;
  flex-flow: row;
  align-items: stretch;
  justify-content: center;

  &--white {
    background: $white;
  }
  &--black {
    background: $black;
  }

  &--light {
    &.section--terracotta {
      background: $terracotta-light;
    }
    &.section--concrete {
      background: $concrete-light;
    }
    &.section--forest {
      background: $forest-light;
    }
    &.section--gold {
      background: $gold-light;
    }
    &.section--teal {
      background: $teal-light;
    }
  }
  &--dark {
    &.section--terracotta {
      background: $terracotta-dark;
    }
    &.section--concrete {
      background: $concrete-dark;
    }
    &.section--forest {
      background: $forest-dark;
    }
    &.section--gold {
      background: $gold-dark;
    }
    &.section--teal {
      background: $teal-dark;
    }
  }


  &__content {
    display: flex;
    flex-flow: column;
    align-items: stretch;

    width: 100%;
    max-width: 1440px;

    &--centered {
      text-align: center;
      align-items: center;
    }
  }
}
