@import "../../variables";

.copyright-section {
  padding: 10px;

  @media only screen and (max-width: 600px) {
    padding: 16px 32px;
    text-align: inherit;
  }

  &__content {
  }

  &__text {
    color: #686868;

    @media only screen and (max-width: 600px) {
      text-align: left;
    }
  }
}
