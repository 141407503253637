//@import "variables";

@font-face {
  font-family: "Apris Thin";
  src: url("../assets/fonts/Apris-Thin.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Apris Thin";
  src: url("../assets/fonts/Apris-ThinItalic.otf") format("opentype");
  font-style: italic;
}

@font-face {
  font-family: "Marr Sans";
  src: url("../assets/fonts/MarrSans-Regular.ttf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Marr Sans";
  src: url("../assets/fonts/MarrSans-RegularItalic.ttf") format("opentype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Marr Sans";
  src: url("../assets/fonts/MarrSans-Semibold.ttf") format("opentype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Marr Sans";
  src: url("../assets/fonts/MarrSans-SemiboldItalic.ttf") format("opentype");
  font-style: italic;
  font-weight: 600;
}


@font-face {
  font-family: "Marr Sans";
  src: url("../assets/fonts/MarrSans-Bold.ttf") format("opentype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Marr Sans";
  src: url("../assets/fonts/MarrSans-BoldItalic.ttf") format("opentype");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("../assets/fonts/MarrSansCond-Semibold.ttf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("../assets/fonts/MarrSansCond-Semibold.ttf") format("opentype");
  font-style: italic;
}

h1 {
  font-family: "Marr Sans Condensed", sans-serif;
  font-weight: 700;
  font-size: 64px;
}

h2 {
  font-family: "Apris Thin", serif;
  font-weight: 100;
  font-size: 64px;
}

h3 {
  font-family: "Marr Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
}

h4 {
  font-family: "Marr Sans", sans-serif;
  font-weight: 600;
  font-size: 24px;
}

p {
  font-family: "Marr Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

strong {
  font-size: 24px;
  font-weight: 600;
  line-height: 1em;
}

small {
  font-weight: 400;
  font-size: 14px;
}

pre {
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
}

button {
  font-family: "Marr Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

a {
  font-family: "Marr Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-decoration: underline;
  color: #000000;
}

.accent {
  font-family: "Apris Thin", serif;
}
