@import 'components';
@import 'pages';
@import 'typography';

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

#root {
}

.MuiContainer-root.MuiContainer-maxWidthLg {
  max-width: 1488px; // 1440 + 24 horizontal padding
}
